/* eslint-disable */
import { Button, Checkbox, Grid } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { navigate } from "gatsby";
import React, { useState, useEffect } from "react";
import Check from "../../assets/Icons/ContactUsForm/check.svg";
import digitalTransformation from "../../assets/Icons/ContactUsForm/Digital_Transformation.svg";
import digitalTransformationHover from "../../assets/Icons/ContactUsForm/Digital_Transformation_red.svg";
import hiringDevTeam from "../../assets/Icons/ContactUsForm/Hiring_Dev_Team.svg";
import hiringDevTeamHover from "../../assets/Icons/ContactUsForm/Hiring_Dev_Team_red.svg";
import machineLearning from "../../assets/Icons/ContactUsForm/Machine_Learning.svg";
import machineLearningHover from "../../assets/Icons/ContactUsForm/Machine_Learning_red.svg";
import projectOutsourcing from "../../assets/Icons/ContactUsForm/Project_outsourcing.svg";
import projectOutsourcingHover from "../../assets/Icons/ContactUsForm/Project_outsourcing_red.svg";
import somethingElse from "../../assets/Icons/ContactUsForm/Something_else.svg";
import somethingElseHover from "../../assets/Icons/ContactUsForm/Something_else_red.svg";
import UnCheck from "../../assets/Icons/ContactUsForm/uncheck.svg";
import DataService from "../../services/api/data_services";
import SnackBar from "../../templates/SnackBar";
import "./ContactUs.scss";
// import DataService from "../../services/api/data_services";

const ContactUs = () => {
  let formData = {
    firstName: "",
    lastName: "",
    organization: "",
    jobTitle: "",
    email: "",
    phone: "",
    location: "",
    message: "",
    interestedIn: [],
  };

  const [hoverImg1, sethoverImg1] = useState(false);
  const [hoverImg2, sethoverImg2] = useState(false);
  const [hoverImg3, sethoverImg3] = useState(false);
  const [hoverImg4, sethoverImg4] = useState(false);
  const [hoverImg5, sethoverImg5] = useState(false);
  const [contactusForm, setcontactusForm] = useState(formData);
  const [checked, setchecked] = useState([]);
  const [approval, setapproval] = useState(false);
  const [approvalOpt, setapprovalOpt] = useState(false);
  const [snackbarStatus, setSnackbarStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [geoLocation, setGeolocation] = useState();

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    margin: {
      width: "100%",
    },
    error: {
      color: "red",
      fontSize: "14px",
      paddingTop: "5px",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    DataService.getData(`https://ipapi.co/json/`).then((res) =>
      setcontactusForm({ ...contactusForm, location: res.city })
    );
  }, []);

  const handleChange = (event, key) => {
    if (key === "firstName") {
      let validatedValue = event.target.value.replace(
        /[0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
      setcontactusForm({ ...contactusForm, [key]: validatedValue });
    } else if (key == "lastName") {
      let validatedValue = event.target.value.replace(
        /[0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
      setcontactusForm({ ...contactusForm, [key]: validatedValue });
    } else if (key == "organization") {
      let validatedValue = event.target.value.replace(
        /[0-9`~!@#$%^&*()_|+\-=?;:<>\{\}\[\]\\\/]/gi,
        ""
      );
      setcontactusForm({ ...contactusForm, [key]: validatedValue });
    } else if (key == "jobTitle") {
      let validatedValue = event.target.value.replace(
        /[0-9`~!@#$%^&*()_|+\-=?;:<>\{\}\[\]\\\/]/gi,
        ""
      );
      setcontactusForm({ ...contactusForm, [key]: validatedValue });
    } else if (key == "email") {
      let validatedValue = event.target.value.replace(
        /[0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
      setcontactusForm({ ...contactusForm, [key]: event.target.value });
    } else if (key == "phone") {
      let validatedValue = event.target.value.replace(
        /[a-zA-Z`~!@#$%^&*()_|\=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
      setcontactusForm({ ...contactusForm, [key]: validatedValue });
    } else if (key == "location") {
      let validatedValue = event.target.value.replace(
        /[0-9`~!@#$%^&*()_|+\-=?;:<>\{\}\[\]\\\/]/gi,
        ""
      );
      setcontactusForm({ ...contactusForm, [key]: validatedValue });
    } else if (key == "message") {
      let validatedValue = event.target.value.replace(/<>\{\}\[\]\\\/]/gi, "");
      setcontactusForm({ ...contactusForm, [key]: event.target.value });
    }
  };

  const handleCheckbox = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setchecked(newChecked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    contactusForm.interestedIn = checked;
    contactusForm.privacy_policy = approval;
    contactusForm.opt_in = approvalOpt;
    setchecked([]);
    setcontactusForm(formData);

    // setapproval(false);
    approval === true &&
      DataService.postData(`admin/api/sendmail`, contactusForm, {
        type: "contactus",
      })
        .then((response) => {
          if (typeof window !== `undefined`) {
            navigate(`/thank-you-contact`);
          }
        })
        .catch((error) => {
          setSnackbarStatus(true);
          setSnackbarMessage(error.message);
        });
  };
  const validateSubmit = (e) => {
    if (approval === true) {
      setShowError(false);
      handleSubmit(e);
    } else {
      setShowError(true);
    }
  };
  return (
    <div className="body-container">
      {/* {navigator.geolocation.getCurrentPosition(console.log,console.log,console.log('geolocation'))} */}
      <SnackBar
        isOpen={snackbarStatus}
        message={snackbarMessage}
        close={() => setSnackbarStatus(false)}
      />
      <Grid className="contactus-subtitle" container direction="column">
        {/* <Grid item xs={12} sm={4} md={6}>
          Fill out and send a form.
        </Grid>
        <Grid item xs={12} sm={4} md={6}>
          Our sales team will contact you promptly
        </Grid> */}
        <Grid className="mt-50" item xs={12} sm={4} md={6}>
          <b>I'm interested in...</b>
        </Grid>
      </Grid>
      <Grid className="mt-20" container justify="space-between" spacing={2}>
        <Grid item xs={6} sm={4} md={2}>
          <div
            role="presentation"
            className={
              checked.includes("Project Outsourcing")
                ? "contactus-icon-area contactus-icon-area-checked"
                : "contactus-icon-area"
            }
            onMouseEnter={() => sethoverImg1(true)}
            onMouseLeave={() => sethoverImg1(false)}
            onClick={() => handleCheckbox("Project Outsourcing")}
          >
            {checked.includes("Project Outsourcing") ? (
              <img src={Check} alt="checkbox" className="checkbox-icon" />
            ) : (
              <img src={UnCheck} alt="checkbox" className="checkbox-icon" />
            )}

            {!hoverImg1 ? (
              <img src={projectOutsourcing} alt="Project Outsourcing" />
            ) : (
              <img src={projectOutsourcingHover} alt="Project Outsourcing" />
            )}

            <div className="interestedin-icon-title">Project Outsourcing</div>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div
            role="presentation"
            className={
              checked.includes("Hiring Dev Team")
                ? "contactus-icon-area contactus-icon-area-checked"
                : "contactus-icon-area"
            }
            onMouseEnter={() => sethoverImg2(true)}
            onMouseLeave={() => sethoverImg2(false)}
            onClick={() => handleCheckbox("Hiring Dev Team")}
          >
            {checked.includes("Hiring Dev Team") ? (
              <img src={Check} alt="checkbox" className="checkbox-icon" />
            ) : (
              <img src={UnCheck} alt="checkbox" className="checkbox-icon" />
            )}
            {!hoverImg2 ? (
              <img src={hiringDevTeam} alt="Hiring Dev Team" />
            ) : (
              <img src={hiringDevTeamHover} alt="Hiring Dev Team" />
            )}
            <div className="interestedin-icon-title">Hiring Dev Team</div>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div
            role="presentation"
            className={
              checked.includes("Machine Learning")
                ? "contactus-icon-area contactus-icon-area-checked"
                : "contactus-icon-area"
            }
            onMouseEnter={() => sethoverImg3(true)}
            onMouseLeave={() => sethoverImg3(false)}
            onClick={() => handleCheckbox("Machine Learning")}
          >
            {checked.includes("Machine Learning") ? (
              <img src={Check} alt="checkbox" className="checkbox-icon" />
            ) : (
              <img src={UnCheck} alt="checkbox" className="checkbox-icon" />
            )}
            {!hoverImg3 ? (
              <img src={machineLearning} alt="Machine Learning" />
            ) : (
              <img src={machineLearningHover} alt="Machine Learning" />
            )}
            <div className="interestedin-icon-title">Machine Learning</div>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div
            role="presentation"
            className={
              checked.includes("Digital Transformation")
                ? "contactus-icon-area contactus-icon-area-checked"
                : "contactus-icon-area"
            }
            onMouseEnter={() => sethoverImg4(true)}
            onMouseLeave={() => sethoverImg4(false)}
            onClick={() => handleCheckbox("Digital Transformation")}
          >
            {checked.includes("Digital Transformation") ? (
              <img src={Check} alt="checkbox" className="checkbox-icon" />
            ) : (
              <img src={UnCheck} alt="checkbox" className="checkbox-icon" />
            )}
            {!hoverImg4 ? (
              <img src={digitalTransformation} alt="Digital Transformation" />
            ) : (
              <img
                src={digitalTransformationHover}
                alt="Digital Transformation"
              />
            )}
            <div className="interestedin-icon-title">
              Digital Transformation
            </div>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div
            role="presentation"
            className={
              checked.includes("Something Else")
                ? "contactus-icon-area contactus-icon-area-checked"
                : "contactus-icon-area"
            }
            onMouseEnter={() => sethoverImg5(true)}
            onMouseLeave={() => sethoverImg5(false)}
            onClick={() => handleCheckbox("Something Else")}
          >
            {checked.includes("Something Else") ? (
              <img src={Check} alt="checkbox" className="checkbox-icon" />
            ) : (
              <img src={UnCheck} alt="checkbox" className="checkbox-icon" />
            )}
            {!hoverImg5 ? (
              <img src={somethingElse} alt="Something Else" />
            ) : (
              <img src={somethingElseHover} alt="Something Else" />
            )}

            <div className="interestedin-icon-title">Something Else</div>
          </div>
        </Grid>
      </Grid>
      <div>
        <Grid className="mt-50" container spacing={2} direction="row">
          <Grid item xs={12} sm={12} md={6}>
            <div className="floating">
              <input
                id="first_name"
                className="floating__input"
                name="first_name"
                type="text"
                placeholder="First Name *"
                onChange={(event) => handleChange(event, "firstName")}
                value={contactusForm.firstName}
                maxlength="50"
              />
              <label
                htmlFor="first_name"
                className="floating__label"
                data-content="First Name *"
              >
                <span className="hidden--visually">First Name</span>
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="floating">
              <input
                id="last_name"
                className="floating__input"
                name="last_name"
                type="text"
                placeholder="Last Name *"
                onChange={(event) => handleChange(event, "lastName")}
                value={contactusForm.lastName}
                maxlength="50"
              />
              <label
                htmlFor="last_name"
                className="floating__label"
                data-content="Last Name *"
              >
                <span className="hidden--visually">Last Name</span>
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="floating">
              <input
                id="Organization"
                className="floating__input"
                name="Organization"
                type="text"
                placeholder="Organisation"
                onChange={(event) => handleChange(event, "organization")}
                value={contactusForm.organization}
                maxlength="50"
              />
              <label
                htmlFor="Organization"
                className="floating__label"
                data-content="Organisation"
              >
                <span className="hidden--visually">Organisation</span>
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="floating">
              <input
                id="job_title"
                className="floating__input"
                name="job_title"
                type="text"
                placeholder="Job Title"
                onChange={(event) => handleChange(event, "jobTitle")}
                value={contactusForm.jobTitle}
                maxlength="50"
              />
              <label
                htmlFor="job_title"
                className="floating__label"
                data-content="Job Title"
              >
                <span className="hidden--visually">Job Title</span>
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="floating">
              <input
                id="email"
                className="floating__input"
                name="email"
                type="email"
                placeholder="Email *"
                onChange={(event) => handleChange(event, "email")}
                value={contactusForm.email}
                maxlength="180"
              />
              <label
                htmlFor="email"
                className="floating__label"
                data-content="Email *"
              >
                <span className="hidden--visually">Email</span>
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="floating">
              <input
                id="phone"
                className="floating__input"
                name="phone"
                type="tel"
                placeholder="Phone *"
                onChange={(event) => handleChange(event, "phone")}
                value={contactusForm.phone}
                maxlength="15"
              />
              <label
                htmlFor="phone"
                className="floating__label"
                data-content="Phone *"
              >
                <span className="hidden--visually">Phone</span>
              </label>
            </div>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6}>
            <div className="floating">
              <input
                id="location"
                className="floating__input"
                name="location"
                type="text"
                placeholder="Location "
                onChange={(event) => handleChange(event, "location")}
                value={contactusForm.location}
                maxlength="50"
              />
              <label
                htmlFor="location"
                className="floating__label"
                data-content="Location "
              >
                <span className="hidden--visually">Location</span>
              </label>
            </div>
          </Grid> */}
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="How can we help?"
              multiline
              rows={3}
              variant="filled"
              id="description"
              name="description"
              onChange={(event) => handleChange(event, "message")}
              value={contactusForm.message}
              InputLabelProps={{
                style: { color: "#0000008a", fontSize: "18px" },
              }}
              InputProps={{
                disableUnderline: true,
                fullWidth: true,
                style: {
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                },
              }}
              style={{ width: "100%" }}
              inputProps={{ maxLength: 10000 }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="contact-mb">
              <div
                role="presentation"
                onChange={() => {
                  setapproval(!approval);
                  setShowError(false);
                  handleChange(!approval, "privacy_policy");
                }}
              >
                <Checkbox
                  color="primary"
                  checked={approval}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
              <div
                style={{ margin: "auto", marginLeft: "5px" }}
                className="approval-text"
              >
                <i>
                  I agree to share my information with you and understand it
                  will be used as described in{" "}
                  <a
                    className="atagclass"
                    target="_blank"
                    href="/privacy-policy"
                  >
                    Digiryte's privacy policy.
                  </a>
                </i>
                {showError === true && (
                  <FormHelperText className={classes.error}>
                    *This permission is required before we collect your
                    information
                  </FormHelperText>
                )}
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6}>
            <div className="contact-mb">
              <div
                role="presentation"
                onChange={() => {
                  setapprovalOpt(!approvalOpt);
                  handleChange(!approvalOpt, "opt_in");
                }}
              >
                <Checkbox
                  color="primary"
                  style={{ padding: "1px", paddingLeft: "10px" }}
                  checked={approvalOpt}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
              <i className="approval-text">
                Opt-in for our featured content!!!
              </i>
            </div>
          </Grid> */}
          <Grid item xs={12} sm={12} md={12}>
            <div
              style={{ marginTop: "20px" }}
              role="presentation"
              onClick={(e) => {
                contactusForm.firstName &&
                  contactusForm.lastName &&
                  contactusForm.email &&
                  contactusForm.phone &&
                  // ) && handleSubmit(e) }}>
                  validateSubmit(e);
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                size="large"
                disabled={
                  !(
                    contactusForm.firstName &&
                    contactusForm.lastName &&
                    contactusForm.email &&
                    contactusForm.phone
                  )
                }
                routepath="/thank-you-contact"
              >
                SUBMIT
              </Button>
            </div>
          </Grid>
        </Grid>
        {/* <SnackBar /> */}
      </div>
    </div>
  );
};

export default ContactUs;
