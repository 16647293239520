import React, { useEffect } from "react";
import Clm2Img from "../assets/Icons/mail.svg";
import Clm1Img from "../assets/Icons/phone.svg";
import contactUsOfficeImage2 from "../assets/Images/ContactUs/Global_Delivery_centre.png";
import contactUsOfficeImage1 from "../assets/Images/ContactUs/Global_Headquarters.png";
import contactUsOfficeImage3 from "../assets/Images/ContactUs/India_Delivery_centre.png";
import ogimage from "../assets/Images/ogImages/contactus@2x.png";
import LocationCards from "../templates/OurLocation";
import mapImage from "../assets/Images/Homepage/Locations/location.png";
import Banglore from "../assets/Images/Homepage/Locations/Banglore.png";
import Chennai from "../assets/Images/Homepage/Locations/Chennai.png";
import Coimbatore from "../assets/Images/Homepage/Locations/Coimbatore.png";
import Hyderabad from "../assets/Images/Homepage/Locations/Hyderabad.png";
import Mumbai from "../assets/Images/Homepage/Locations/Mumbai.png";
import UK from "../assets/Images/Homepage/Locations/UK.png";
import BannerBgImage from "../assets/Images/ContactUs/ContactUS-Banner.png";
import mapImage1 from "../assets/Images/mobileView/Our_Locations/OurLocation.png";
import mChennai from "../assets/Images/mobileView/Our_Locations/Chennai.png";
import mBangalore from "../assets/Images/mobileView/Our_Locations/bangalore.png";
import mCoimbatore from "../assets/Images/mobileView/Our_Locations/Coimbatore.png";
import mHyderabad from "../assets/Images/mobileView/Our_Locations/Hyderabad.png";
import Manchester from "../assets/Images/mobileView/Our_Locations/Manchester.png";
import mMumbai from "../assets/Images/mobileView/Our_Locations/Mumbai.png";
import BackArrowIcon from "../assets/Images/accordian.svg";
import Typography from "@material-ui/core/Typography";
import Button from "../components/Button";

import ImageBanner from "../templates/ImageBanner";

//contact us location card
import ContactUsLocationCard from "../components/ContactUsLocationCard";
import Layout from "../components/layouts/layout";
import { BASE_SITE_URL } from "../shared/constants";
import ContactUsComponent from "../templates/ContactUs";
//Two column contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";

const ContactUs = () => {
  useEffect(() => {
    // document.getElementById("header").classList.add("toolbar_onscroll_article");
  });

  const contactUsCardData = [
    {
      image: contactUsOfficeImage1,
      alt: "GLOBAL HEADQUARTERS",
      title: "GLOBAL HEADQUARTERS",
      addressLine1:
        "Digiryte Ltd, Peter House, Oxford Street,Manchester, M1 5AN, United Kingdom.",
      addressLine2: "",
      googleMapLink: "",
      viewMapLink:
        "https://www.google.com/maps/place/Digiryte/@53.4772249,-2.2435506,17z/data=!4m5!3m4!1s0x487b72683ecbb209:0xc0c922b6d33de7bc!8m2!3d53.4771363!4d-2.2437523",
    },
    {
      image: contactUsOfficeImage2,
      alt: "Global Delivery centre",
      title: "GLOBAL DELIVERY CENTRE",
      addressLine1:
        "Digiryte Delivery Centre, Peter House,Oxford Street, Manchester, M1 5AN, United Kingdom. ",
      addressLine2: "",
      googleMapLink: "",
      viewMapLink:
        "https://www.google.com/maps/place/Digiryte/@53.4772249,-2.2435506,17z/data=!4m5!3m4!1s0x487b72683ecbb209:0xc0c922b6d33de7bc!8m2!3d53.4771363!4d-2.2437523",
    },
    {
      image: contactUsOfficeImage3,
      alt: "APAC Delivery centre",
      title: "APAC DELIVERY CENTRE",
      addressLine1:
        "Flat Plot No. 42-48 Flat No. 406,Gayathri nest, Telcom Nagar, Gachibowli, Hyderabad - 500032.",
      addressLine2: "",
      googleMapLink: "",
      viewMapLink:
        "https://www.google.com/maps/place/Humming+Bird+-+Gayathri+Nest/@17.4329208,78.3680162,15z/data=!4m18!1m9!3m8!1s0x0:0x67594f196a899346!2sHumming+Bird+-+Gayathri+Nest!5m2!4m1!1i2!8m2!3d17.433016!4d78.367892!3m7!1s0x0:0x67594f196a899346!5m2!4m1!1i2!8m2!3d17.433016!4d78.367892",
    },
  ];
  /**
   * TwoColumnContactInfo
   */
  const LocationCardData = {
    mapImage: mapImage,
    locationImages: [
      {
        image: UK,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: Banglore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: Hyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: Mumbai,
        imgTitle: "Mumbai",
        route: "/locations/mumbai",
      },
      {
        image: Chennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: Coimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };
  const LocationCardData1 = {
    mapImage: mapImage1,
    locationImages: [
      {
        image: Manchester,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: mBangalore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: mHyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: mMumbai,
        imgTitle: "Mumbai",
        route: "/locations/mumbai",
      },
      {
        image: mChennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: mCoimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };
  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    altImg: BannerBgImage,
    component: "Who",
    bnrImgTitle: "Contact Us",
  };
  return (
    <Layout
      seo={{
        title: "Ready to Build Your Dedicated Team? | Contact Us",
        ogtitle: "Ready to Build Your Dedicated Team? | Contact Us",
        description:
          "Speak to a Digiryte expert to start building a high-performance dedicated team from India's top 3% vetted developers.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/contact-us`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="Home"
          routepath="/"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Home
        </Typography>
      </div>
      <div className="mt-100 body-container">
        <div className="contact-us-title-header">
          How can we help you today?
        </div>
        <div style={{ width: "125px" }} className="red-line"></div>
      </div>
      <div className="mt-50">
        <ContactUsComponent />
      </div>
      <div className="body-container mt-70 ttc">
        <h2 className="title-header">
          <span className="red-line3letter red-3letter-mbl">Our </span>
          Locations
        </h2>
      </div>
      <div className="hide-in-mobile">
        <LocationCards
          mapImage={mapImage}
          label="View Location"
          LocationCardData={LocationCardData}
        />
      </div>
      <div className="hide-in-web">
        <LocationCards
          mapImage={mapImage1}
          label="View Location"
          LocationCardData={LocationCardData1}
        />
      </div>
      {/* <div className="body-container mt-50">
        <ContactUsLocationCard contactUsCardData={contactUsCardData} />
      </div> */}
      {/* <br /> */}
      {/* <div className="mt-50"> */}
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
      {/* </div> */}
    </Layout>
  );
};

export default ContactUs;
